<template>
	<v-container fluid>
		<v-progress-linear
			height="15px"
			:value="(progress / totalProgress) * 100"
			v-if="progressLoader"
		></v-progress-linear>
		<v-layout row wrap class="mt-4 mx-2">
			<v-flex class="pa-3" hidden-xs sm8 md9 lg10 layout row>
				<v-btn dark color="blue" v-if="canSendSome" @click="sendAllToDian()">
					{{ "Enviar Todo a DIAN" | trans }}
				</v-btn>
				<v-btn dark color="blue" v-if="isAllSent && canContabilizarSome" @click="contabilizarAll()">
					{{ "Enviar Todo a Contabilizacion" | trans }}
				</v-btn>
				<v-btn dark color="red" v-if="canRegenerate" @click="initNomina()">
					{{ "Regenerar Nomina" | trans }}
				</v-btn>
				<v-text-field
					dense
					class="mx-3"
					append-icon="search"
					v-model="query"
					outlined
					:label="'search' | trans"
				></v-text-field>
			</v-flex>
			<v-flex xs12 sm4 md3 lg2>
				<default-field
					:props="{
						dense: true,
						type: 'month',
						outlined: true,
						prependIcon: 'event',
						required: true,
						singleLine: true,
						label: 'date',
					}"
					v-model="date"
					@input="changeDates"
				></default-field>
			</v-flex>
		</v-layout>
		<div v-if="!ready" class="text-center">
			<v-progress-circular color="primary" size="100" indeterminate></v-progress-circular>
		</div>
		<v-layout v-else row wrap class="mx-2" align-center justify-center align-content-center>
			<v-flex>
				<v-card dense outlined>
					<v-list subheader three-line>
						<v-subheader>
							<b>{{ "worker" | trans }}</b>
							<v-spacer></v-spacer>
							<v-btn-toggle color="primary" dense v-model="filter" mandatory>
								<v-btn>Activos</v-btn>
								<v-btn>Soporte</v-btn>
								<v-btn>Reemplazo</v-btn>
								<v-btn>Eliminacion</v-btn>
							</v-btn-toggle>
						</v-subheader>
						<template v-if="nomina.length">
							<template v-for="item in limitBy(filterBy(filteredNomina, query), 100)">
								<v-divider :key="item.worker.id + '-div'"></v-divider>
								<v-list-item :key="item.worker.id" @click="selectWorker(item)">
									<v-list-item-avatar class="hidden-sm-and-down" tile size="64">
										<v-img :src="item.worker.image_url"></v-img>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title
											>[{{ item.number }}] - {{ item.worker.full_name }}
											<v-chip small label color="red" v-if="item.type === 'eliminacion'"> Nota Eliminacion</v-chip>
										</v-list-item-title>
										<v-list-item-subtitle>{{ item.worker.document }}</v-list-item-subtitle>
										<v-list-item-subtitle>
											<b>{{ "salary" | trans }}:</b>
											{{ item.worker.salary | money }}
										</v-list-item-subtitle>
										<v-list-item-subtitle v-if="item.codigo_dian">
											<b>CUNE:</b>
											{{ item.codigo_dian }}
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-btn
											block
											label
											dark
											:color="item.dian_status == 'DIAN_ACEPTADO' ? 'green' : 'purple'"
											v-if="item.codigo_dian"
											small
											@click.stop="getFromDian(item)"
										>
											<b>{{ "status" | trans }}:</b> &nbsp;{{ item.dian_status }}</v-btn
										>

										<v-btn dark block color="blue" v-else @click.stop="sendToDian(item)" depressed small>
											{{ "Enviar a Dian" | trans }}
											<v-icon right>mdi-send</v-icon>
										</v-btn>
										<template v-if="api.user.cliente && api.user.cliente.contabilidad">
											<template v-if="item.dian_status == 'DIAN_ACEPTADO'">
												<v-btn
													block
													label
													dark
													v-if="item.contabilidad_id && item.contabilidad"
													color="red darken-2"
													target="contabilidad"
													@contextmenu.prevent="contabilizar(item, true, true)"
													:href="`https://facturacion.eycproveedores.com/api/contabilidades/${item.contabilidad_id}/pdf?token=${api.user.token}`"
													small
												>
													<v-icon left>mdi-file-pdf-box</v-icon>
													<b>{{ "Ver Contabilidad" | trans }}</b>
												</v-btn>
												<v-btn color="orange darken-2" block label dark v-else small @click.stop="contabilizar(item)">
													<b>{{ "Contabilizar" | trans }}</b>
													<v-icon right>mdi-send</v-icon>
												</v-btn>
											</template>
										</template>
										<v-btn
											block
											label
											dark
											color="warning"
											x-small
											class="mt-1"
											v-if="
												item.dian_response &&
												item.dian_response.dian_messages &&
												item.dian_response.dian_messages.length &&
												!item.dian_response.dian_messages[0].includes(', ha sido autorizada.')
											"
											@click.stop="viewErrors(item)"
										>
											Contiene Mensajes
										</v-btn>
										<div>
											<v-btn
												dark
												x-small
												class="mt-1"
												outlined
												color="red darken-2"
												v-link.stop="`nominas/${item.id}/pdf?withProvisiones=false`"
											>
												<v-icon left>mdi-file-pdf-box</v-icon>
												{{ "literals.view_resource" | trans }}
												{{ "Desprendible" | trans }}
											</v-btn>
											<v-btn
												dark
												x-small
												class="mt-1"
												outlined
												color="red darken-2"
												v-link.stop="`nominas/${item.id}/pdf`"
											>
												<v-icon left>mdi-file-pdf-box</v-icon>
												{{ "literals.view_resource" | trans }}
												{{ "pdf" | trans }}
											</v-btn>
											<v-btn
												dark
												x-small
												class="mt-1"
												outlined
												color="green darken-2"
												v-link.stop="`nominas/${item.id}/xml`"
											>
												<v-icon left>mdi-file-code</v-icon>
												{{ "literals.view_resource" | trans }}
												{{ "xml" | trans }}
											</v-btn>
										</div>
										<span>
											<b>{{ subtotal(item) | money }}</b>
											| {{ item.worker.payment_type | trans("date") }}
										</span>
									</v-list-item-action>
								</v-list-item>
							</template>
						</template>
					</v-list>
					<v-divider></v-divider>
					<v-subheader>
						<h3>{{ "workers" | trans }}: {{ nomina.length }}</h3>
						<v-spacer></v-spacer>
						<h3>{{ "total" | trans }}: {{ nominaTotal() | money }}</h3>
					</v-subheader>
				</v-card>
			</v-flex>
			<v-flex class="text-center" v-if="nomina.length === 0 && filter === '0'">
				<v-btn x-large color="purple" class="my-5" dark @click="initNomina()">
					Iniciar Periodo
					<v-icon right>mdi-check</v-icon>
				</v-btn>
			</v-flex>
		</v-layout>
		<v-dialog v-model="dialog" max-width="80%">
			<v-toolbar v-if="selected" dark :color="selected.type === 'eliminacion' ? 'red' : 'primary darken-2'" flat>
				<h4 layout column>
					{{ selected.worker.full_name }} - {{ selected.worker.document }}
					<br />
					<small>{{ selected.worker.salary | money }}</small>
					-
					<small>{{ selected.worker.payment_type | trans }}</small>
				</h4>
				<v-spacer></v-spacer>
				<v-chip label color="yellow darken-4" v-if="selected.type === 'eliminacion'"> NOTA ELIMINACION </v-chip>
				<v-icon @click="dialog = false">mdi-close</v-icon>
			</v-toolbar>
			<v-card v-if="selected">
				<v-card-text class="pa-4 tables">
					<v-form v-model="isValid">
						<template v-if="selected.codigo_dian && selected.dian_status">
							<v-spacer></v-spacer>
							<v-alert type="warning" dense border="left">
								{{ "__.already sent, this will generate new document" | trans }}
							</v-alert>
						</template>
						<h2>{{ "Devengables" | trans }}</h2>
						<v-divider></v-divider>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-center">{{ "description" | trans }}</th>
										<th class="text-center">{{ "quantity" | trans }}</th>
										<th class="text-center" style="width: 95px">{{ "percent" | trans }}</th>
										<th class="text-center">{{ "value" | trans }}</th>
										<th class="text-center">{{ "total" | trans }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(de, i) in selected.devengables" :key="i">
										<td class="text-center">
											{{ de.description }}
											<v-icon
												v-if="!de.mandatory"
												color="red"
												@click="
													selected.devengables.splice(i, 1);
													recalculatePrestaciones();
												"
												>mdi-delete</v-icon
											>
										</td>
										<td class="text-center">
											<v-text-field
												:disabled="selected.type === 'eliminacion'"
												v-model.number="de.quantity"
												:rules="toRules('devengable', de.description, 'quantity')"
												solo
												@input="recalculatePrestaciones"
												type="number"
												min="0"
												step="any"
												single-line
												flat
												dense
												class="my-1 mx-auto"
												style="width: 75px"
											></v-text-field>
										</td>
										<td style="width: 95px">
											<v-text-field
												:disabled="selected.type === 'eliminacion'"
												v-if="de.value_type === 'percentage'"
												v-model.number="de.percent"
												:rules="toRules('devengable', de.description, 'percent')"
												@input="calculateNewValue(de, selected.devengables, selected.deducibles)"
												solo
												type="number"
												min="0"
												max="100"
												step="any"
												single-line
												flat
												dense
												class="my-1 mx-auto"
												style="width: 75px"
											></v-text-field>
										</td>
										<td class="text-center">
											<v-text-field
												:disabled="selected.type === 'eliminacion'"
												v-model="de.value"
												:rules="toRules('devengable', de.description, 'value')"
												@input="recalculatePrestaciones"
												prepend-inner-icon="mdi-currency-usd"
												:hint="de.value | money"
												persistent-hint
												solo
												type="number"
												min="0"
												step="any"
												flat
												dense
												class="my-1 mx-auto text-center"
												style="width: 175px"
											></v-text-field>
										</td>
										<th class="text-center">
											{{ (de.value * de.quantity) | money }}
											<span class="accent--text" v-if="de.description.toLowerCase() == 'cesantias'">
												<br />
												Intereses: {{ ((de.value * de.quantity * 12) / 100) | money }}
											</span>
										</th>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<th>
											<v-autocomplete
												:disabled="selected.type === 'eliminacion'"
												:label="'crud.add' | trans"
												:items="availableDevengables"
												outlined
												dense
												item-text="description"
												item-value="description"
												class="ma-1"
												v-model="added"
												return-object
												@input="addDevengable"
											></v-autocomplete>
										</th>
										<th></th>
										<th></th>
										<th class="text-center">{{ "total" | trans }}</th>
										<th class="text-center">
											{{ selected.devengables.reduce((sum, d) => (sum += getConceptTotal(d)), 0) | money }}
										</th>
									</tr>
								</tfoot>
							</template>
						</v-simple-table>
						<h2>{{ "Deducibles" | trans }}</h2>
						<v-divider></v-divider>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-center">{{ "description" | trans }}</th>
										<th class="text-center">{{ "quantity" | trans }}</th>
										<th class="text-center" style="width: 95px">{{ "percent" | trans }}</th>
										<th class="text-center">{{ "value" | trans }}</th>
										<th class="text-center">{{ "total" | trans }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(de, i) in selected.deducibles" :key="i">
										<td class="text-center">
											{{ de.description }}
											<v-icon
												v-if="!de.mandatory"
												color="red"
												@click="
													selected.deducibles.splice(i, 1);
													recalculatePrestaciones();
												"
												>mdi-delete</v-icon
											>
										</td>
										<td class="text-center">
											<v-text-field
												:disabled="selected.type === 'eliminacion'"
												v-model="de.quantity"
												@input="recalculatePrestaciones"
												:rules="toRules('deducible', de.description, 'quantity')"
												solo
												type="number"
												min="0"
												step="any"
												single-line
												flat
												dense
												class="my-1 mx-auto"
												style="width: 75px"
											></v-text-field>
										</td>
										<td style="width: 95px">
											<v-text-field
												:disabled="selected.type === 'eliminacion'"
												v-if="de.value_type === 'percentage'"
												v-model.number="de.percent"
												:rules="toRules('deducible', de.description, 'percent')"
												@input="calculateNewValue(de, selected.devengables, selected.deducibles)"
												solo
												type="number"
												min="0"
												max="100"
												step="any"
												single-line
												flat
												dense
												class="my-1 mx-auto"
												style="width: 75px"
											></v-text-field>
										</td>
										<td class="text-center">
											<v-text-field
												:disabled="selected.type === 'eliminacion'"
												v-model="de.value"
												:rules="toRules('deducible', de.description, 'value')"
												prepend-inner-icon="mdi-currency-usd"
												:hint="de.value | money"
												persistent-hint
												@input="recalculatePrestaciones"
												solo
												type="number"
												min="0"
												step="any"
												flat
												dense
												class="my-1 mx-auto text-center"
												style="width: 175px"
											></v-text-field>
										</td>
										<th class="text-center">{{ (de.value * de.quantity) | money }}</th>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<th>
											<v-autocomplete
												:disabled="selected.type === 'eliminacion'"
												:label="'crud.add' | trans"
												:items="availableDeductibles"
												outlined
												dense
												item-text="description"
												item-value="description"
												class="ma-1"
												v-model="added"
												return-object
												@input="addDeductible"
											></v-autocomplete>
										</th>
										<th></th>
										<th></th>
										<th class="text-center">{{ "total" | trans }}</th>
										<th class="text-center">
											{{ selected.deducibles.reduce((sum, d) => (sum += getConceptTotal(d)), 0) | money }}
										</th>
										<th></th>
									</tr>
								</tfoot>
							</template>
						</v-simple-table>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-textarea
						hide-details
						rows="1"
						v-model="selected.metadata.notes"
						outlined
						dense
						:label="'notes' | trans"
					></v-textarea>
				</v-card-actions>
				<v-card-actions>
					<v-btn
						depressed
						large
						color="red"
						v-if="selected.codigo_dian && selected.dian_status"
						@click="createDelete(selected)"
						>{{ "crud.delete" | trans }}</v-btn
					>
					<v-spacer></v-spacer>
					<b> {{ "total" | trans }}: {{ subtotal(selected) | money }} </b>
					<v-spacer></v-spacer>
					<v-btn depressed large @click="dialog = false">{{ "cancel" | trans }}</v-btn>
					<v-btn
						depressed
						:disabled="!isValid"
						large
						color="primary"
						:loading="loading"
						@click="saveNomina(selected)"
						>{{ "crud.save" | trans }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="errorDialog" max-width="80%">
			<v-card>
				<v-toolbar dark dense color="primary darken-2" flat>
					<h4 layout column>Errores</h4>
					<v-spacer></v-spacer>
					<v-icon @click="errorDialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-card-text>
					<ul>
						<li v-for="error in errors" :key="error">
							{{ error }}
						</li>
					</ul>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import moment from "moment";
import { getConceptTotal, getDevengables, getDeducibles, getBaseSalarial, importFromExcel } from "../services/utils";
import { toRules } from "@/services/validator";
import DefaultField from "@/components/main/DefaultField";
import Swal from "sweetalert2";
export default {
	components: { DefaultField },
	mounted() {
		this.toolbar.buttons = [
			{
				text: `${this.$options.filters.trans("import")} ${this.$options.filters.trans("excel")}`,
				icon: "mdi-file-excel",
				handler: async () => {
					try {
						let [allWorkers, { workers, nomina }] = await Promise.all([this.api.load("workers"), importFromExcel()]);
						allWorkers = this.api.mapToCollection(allWorkers, "document");
						this.totalProgress = workers.length + nomina.length;
						this.progress = 0;
						this.progressLoader = true;
						for (const worker of workers) {
							const existingWorker = allWorkers[`${worker.document}`.trim()];
							let promise = null;
							if (existingWorker) {
								promise = this.api.put(`workers/${existingWorker.id}`, worker);
							} else {
								promise = this.api.post(`workers`, worker);
							}
							const response = await promise;
							this.progress++;
							worker.id = response.data.id;
						}

						for (const item of nomina) {
							await this.api.post(`nominas`, { ...item, worker: undefined, worker_id: item.worker.id });
							this.progress++;
						}
					} catch (error) {
						console.error(error);
						this.api.handleError(error);
					}
					this.getData();
					this.progressLoader = false;
				},
			},
		];
	},
	beforeDestroy() {
		this.toolbar.buttons = [];
	},
	data() {
		return {
			filter: "0",
			filterOptions: ["currents,1", "type,soporte", "type,reemplazo", "type,eliminacion"],
			query: "",
			date: moment().format("YYYY-MM"),
			start: moment().startOf("month"),
			end: moment().endOf("month"),
			ready: true,
			loading: false,
			isValid: false,
			workers: [],
			concepts: [],
			nomina: null,
			dialog: false,
			errorDialog: false,
			errors: [],
			selected: null,
			ref: null,
			added: null,
			totalProgress: 0,
			progress: 0,
			progressLoader: false,
		};
	},
	methods: {
		getConceptTotal,
		toRules(type, description, field) {
			let rules = [];
			let concept;
			if (type === "devengable")
				concept = getDevengables(this.concepts, this.selected.worker).find((d) => d.description === description);
			else concept = getDeducibles(this.concepts, this.selected.worker).find((d) => d.description === description);
			if (concept && concept.rules && concept.rules[field]) rules = toRules(concept.rules[field], this.selected, field);
			return rules;
		},
		changeDates() {
			const date = moment(this.date);
			this.$router.push(
				`/nomina/${date.year().toString().padStart(4, "0")}/${`${date.month() + 1}`.toString().padStart(2, "0")}`
			);
		},
		updateDate() {
			this.start = moment(this.year + "-" + this.month + "-01").startOf("month");
			this.end = moment(this.year + "-" + this.month + "-01").endOf("month");
			this.date = `${this.year.toString().padStart(4, "0")}-${this.month.toString().padStart(2, "0")}`;
			this.getData();
		},
		async getData() {
			this.ready = false;
			try {
				let uri = `nominas?order[nomina_id]=asc&include=worker,contabilidad&scope[period]=${this.year}-${this.month}`;
				[this.workers, this.concepts, this.nomina] = await Promise.all([
					this.api.load("workers?scope[actives]=1"),
					this.api.load("concepts"),
					this.api.getAll(uri),
				]);
			} catch (error) {
				console.error(error);
				this.api.handleError(error);
			}
			this.ready = true;
		},
		async initNomina() {
			if (!confirm(this.$options.filters.trans("__.are you sure?"))) {
				return;
			}
			this.ready = false;
			try {
				await this.api.post(`nominas/initialize?force=true&date=${this.year}-${this.month}-01`);
				await this.getData();
			} catch (error) {
				this.api.handleError(error);
			}
			this.ready = true;
		},
		viewDesprendible(item) {
			this.api.downloadFile(
				`nominas/${item.id}/pdf?withProvisiones=false`,
				`Desprendible ${item.worker.full_name}.pdf`
			);
		},
		viewPdf(item) {
			this.api.downloadFile(`nominas/${item.id}/pdf`, `Nomina ${item.worker.full_name}.pdf`);
		},
		viewXml(item) {
			this.api.downloadFile(`nominas/${item.id}/xml`, `${item.codigo_dian}.pdf`);
		},
		viewErrors(item) {
			this.errors = item.dian_response.dian_messages;
			this.errorDialog = true;
		},
		async getFromDian(item) {
			if (item.dian_status === "DIAN_NO_ENVIADO" || item.dian_status === "DIAN_RECHAZADO") {
				return this.sendToDian(item);
			}
			const dialog = Swal.fire({
				title: "Procesando...",
				type: "alert",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			try {
				const resp = await this.api.get(`nominas/${item.id}/getFromDian`);
				if (resp.data.status !== "error") {
					this.api.successful(resp);
				} else {
					this.handleError({ response: resp });
				}
			} catch (error) {
				this.handleError(error);
			}
			dialog.close();
			await this.getData();
		},
		async sendToDian(item, ask = true) {
			let dialog;
			if (ask) {
				const response = await Swal.fire({
					title: `${this.$options.filters.trans("__.are you sure?")}`,
					type: "question",
					text: `Se enviara a la DIAN los datos de nomina para ${
						item.worker.full_name
					}. (Total: ${this.$options.filters.money(this.subtotal(item))})`,
					showConfirmButton: true,
					showCancelButton: true,
					focusCancel: true,
				});
				if (!response.value) {
					return;
				}
				dialog = Swal.fire({
					title: "Procesando...",
					type: "alert",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
			}
			try {
				const resp = await this.api.post(`nominas/${item.id}/sendToDian`);
				if (ask) this.api.successful(resp);
				Object.assign(item, resp.data);
			} catch (error) {
				this.handleError(error);
			}
			if (ask) dialog.close();
		},
		async sendAllToDian() {
			const items = this.nomina.filter((nom) => !nom.codigo_dian);
			const response = await Swal.fire({
				title: `${this.$options.filters.trans("__.are you sure?")}`,
				type: "question",
				text: `Se Enviaran a la dian ${items.length} Documentos`,
				showConfirmButton: true,
				showCancelButton: true,
				focusCancel: true,
			});
			if (!response.value) {
				return;
			}
			const dialog = Swal.fire({
				title: "Procesando...",
				type: "alert",
				allowEscapeKey: false,
				allowOutsideClick: false,
				progressLoader: true,
				showConfirmButton: false,
				showCancelButton: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			try {
				let total = 0;
				for (const item of items) {
					await this.sendToDian(item, false);
					dialog.update({
						text: `${++total} de ${items.length}`,
					});
				}
				dialog.close();
				this.api.successful("Completado");
			} catch (error) {
				console.error(error);
				dialog.close();
			}
		},

		async contabilizarAll() {
			const items = this.nomina.filter((nom) => nom.codigo_dian);
			const response = await Swal.fire({
				title: `${this.$options.filters.trans("__.are you sure?")}`,
				type: "question",
				text: `Se Enviaran a la Contabilizacion ${items.length} Documentos`,
				showConfirmButton: true,
				showCancelButton: true,
				focusCancel: true,
			});
			if (!response.value) {
				return;
			}
			const dialog = Swal.fire({
				title: "Procesando...",
				type: "alert",
				allowEscapeKey: false,
				allowOutsideClick: false,
				progressLoader: true,
				showConfirmButton: false,
				showCancelButton: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			try {
				let total = 0;
				for (const item of items) {
					await this.sendToDian(item, false);
					dialog.update({
						text: `${++total} de ${items.length}`,
					});
				}
				dialog.close();
				this.api.successful("Completado");
			} catch (error) {
				console.error(error);
				dialog.close();
			}
		},
		selectWorker(item) {
			this.ref = item;
			this.selected = JSON.parse(JSON.stringify(item));
			if (!this.selected.metadata || Array.isArray(this.selected.metadata)) {
				this.$set(this.selected, "metadata", { notes: "" });
			}
			this.dialog = true;
		},
		addDevengable(item) {
			if (item.value_type === "percentage") {
				const base = getBaseSalarial(this.selected.devengables);
				const newVal = (base * item.percent) / 100;
				this.$set(item, "value", newVal);
			} else {
				this.$set(item, "value", item.value || 1);
			}
			this.selected.devengables.push(item);
			this.recalculatePrestaciones();
			this.added = null;
		},
		addDeductible(item) {
			if (item.value_type === "percentage") {
				const base = getBaseSalarial(this.selected.devengables);
				const newVal = (base * item.percent) / 100;
				this.$set(item, "value", newVal);
			} else {
				console.log(item);
				this.$set(item, "value", item.value || 1);
			}

			this.selected.deducibles.push(item);
			this.recalculatePrestaciones();
			this.added = null;
		},
		calculateNewValue(item, devengables) {
			if (item.value_type === "percentage") {
				const base = getBaseSalarial(devengables);
				const newVal = (base * item.percent) / 100;
				this.$set(item, "value", newVal);
			} else {
				this.$set(item, "value", item.value || 1);
			}
			this.recalculatePrestaciones();
		},
		recalculatePrestaciones() {
			const base = getBaseSalarial(this.selected.devengables);
			console.log(this.selected.devengables);
			const salarioBasico = this.selected.devengables.find((d) => d.description == "Salario Basico");
			if (!salarioBasico) {
				return;
			}
			const workedDays = salarioBasico.quantity;
			const salud = this.selected.deducibles.find((d) => d.description === "Aportes Salud (4%)");
			if (salud) {
				salud.value = (base * 4) / 100 / workedDays;
				salud.quantity = workedDays;
			}

			const pension = this.selected.deducibles.find((d) => d.description === "Aportes Pension (4%)");
			if (pension) {
				pension.value = (base * 4) / 100 / workedDays;
				pension.quantity = workedDays;
			}

			const fondoSolidaridad = this.selected.deducibles.find((d) =>
				d.description.startWith("Fondo de solidaridad Pensional (")
			);
			if (fondoSolidaridad) {
				const percentage = fondoSolidaridad.description.match(/\(([^)]+)\)/)[1].replace("%", "");
				fondoSolidaridad.value = (base * percentage) / 100 / workedDays;
				fondoSolidaridad.percent = percentage;
				fondoSolidaridad.quantity = workedDays;
			}
		},
		subtotal(item) {
			return (
				item.devengables.reduce((sum, d) => (sum += getConceptTotal(d)), 0) -
				item.deducibles.reduce((sum, d) => (sum += getConceptTotal(d)), 0)
			);
		},
		nominaTotal() {
			return this.nomina
				.filter((n) => !n.nomina_id)
				.filter((n) => !["eliminado", "reemplazado"].includes(n.status))
				.filter((n) => !["eliminacion"].includes(n.type))
				.reduce((sum, v) => (sum += this.subtotal(v)), 0);
		},
		async saveNomina(nominaItem) {
			if (nominaItem.dian_status && nominaItem.codigo_dian) {
				return this.createReplacement(nominaItem);
			}
			try {
				this.loading = true;
				await this.api.put(`nominas/${nominaItem.id}`, {
					devengables: nominaItem.devengables,
					deducibles: nominaItem.deducibles,
					metadata: nominaItem.metadata,
				});
				this.item = Object.assign(this.ref, nominaItem);
			} catch (error) {
				this.handleError(error);
				this.loading = false;
				return;
			}
			this.dialog = false;
			this.loading = false;
		},
		async createReplacement(nominaItem) {
			const response = await Swal.fire({
				title: `${this.$options.filters.trans("__.are you sure?")}`,
				type: "question",
				text: this.$options.filters.trans("__.already sent, this will generate new document"),
				showConfirmButton: true,
				showCancelButton: true,
				focusCancel: true,
			});
			if (!response.value) {
				return;
			}
			try {
				this.loading = true;
				await this.api.post(`nominas/${nominaItem.id}/replacement`, {
					devengables: nominaItem.devengables,
					deducibles: nominaItem.deducibles,
					metadata: nominaItem.metadata,
				});
				this.getData();
			} catch (error) {
				this.handleError(error);
				this.loading = false;
				return;
			}
			this.dialog = false;
			this.loading = false;
		},
		async createDelete(nominaItem) {
			const response = await Swal.fire({
				title: `${this.$options.filters.trans("__.are you sure?")}`,
				type: "question",
				text: this.$options.filters.trans("__.already sent, this will generate new document"),
				showConfirmButton: true,
				showCancelButton: true,
				focusCancel: true,
			});
			if (!response.value) {
				return;
			}
			try {
				this.loading = true;
				await this.api.post(`nominas/${nominaItem.id}/delete`, {
					devengables: nominaItem.devengables,
					deducibles: nominaItem.deducibles,
					metadata: nominaItem.metadata,
				});
				this.getData();
			} catch (error) {
				this.handleError(error);
				this.loading = false;
				return;
			}
			this.dialog = false;
			this.loading = false;
		},
		async contabilizar(nominaItem, ask = true, force = false) {
			let dialog;
			if (ask) {
				const response = await Swal.fire({
					title: `${this.$options.filters.trans("__.are you sure?")}`,
					type: "question",
					showConfirmButton: true,
					showCancelButton: true,
					focusCancel: true,
				});
				if (!response.value) {
					return;
				}
				dialog = Swal.fire({
					title: "Procesando...",
					type: "alert",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
			}
			try {
				this.loading = true;
				const resp = await this.api.post(`nominas/${nominaItem.id}/contabilizar${force ? "?force=true" : ""}`);
				Object.assign(nominaItem, resp.data);
				if (ask) {
					this.getData();
				}
			} catch (error) {
				this.handleError(error);
				this.loading = false;
				return;
			}
			this.dialog = false;
			this.loading = false;
			if (ask) dialog.close();
		},
		getPath(path) {
			if (!path) return "Error";
			if (path.length === 1) return path[0];
			return path
				.map((p) => {
					if (isNaN(parseInt(p))) return this.$options.filters.trans(p);
					else return " en la posicion [" + (parseInt(p) + 1) + "]";
				})
				.join(" ")
				.replace("payroll-entry", "");
		},
		handleError(err = "Error!") {
			console.error(err);
			var text = "Internal Server Error";
			var error_list = [];
			if (err.data && !err.response) err.response = { data: err.data };
			if (err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
				return Swal.fire({
					icon: "error",
					showConfirmButton: true,
					title: err.response.data.data.message,
				});
			}
			if (err.response && err.response.data && err.response.data.results && err.response.data.results.data.errors) {
				if (Array.isArray(err.response.data.results.data.errors)) {
					text = `<ul>${err.response.data.results.data.errors.map((e) => {
						return `<li><b>${this.getPath(e.path)}:</b> ${e.error}</li>`;
					})}</ul>`;
					return Swal.fire({
						icon: "error",
						showConfirmButton: true,
						title: "Error en los datos",
						html: text,
					});
				}
				return Swal.fire({
					icon: "error",
					showConfirmButton: true,
					title: err.response.data.results.data.errors,
				});
			}
			if (err.response) {
				if (err.response.status && this.api.trans && this.api.trans.http && this.api.trans.http[err.response.status])
					text = this.api.trans.http[err.response.status];
				if (err.response.data && err.response.data.errors)
					for (const k in err.response.data.errors) error_list = error_list.concat(err.response.data.errors[k]);
				if (err.response.data && err.response.data.results) error_list = [err.response.data.results];
			}
			if (error_list.length > 0) {
				return Swal.fire({
					title: text,
					icon: "warning",
					html: `<div>
					${error_list.map((e) => `<span>${e}</span>`).join("<br>")}
				</div>`,
					timer: 10000,
				});
			}
			if (typeof err == "string") text = err;
			return Swal.fire({
				icon: "error",
				toast: true,
				position: "bottom",
				showConfirmButton: false,
				timer: 3000,
				title: text,
			});
		},
	},
	props: ["year", "month"],
	watch: {
		year: {
			immediate: true,
			handler() {
				this.updateDate();
			},
		},
		month: {
			immediate: false,
			handler() {
				this.updateDate();
			},
		},
	},
	computed: {
		filteredNomina() {
			return (
				this.nomina &&
				this.nomina
					.filter((n) => n.worker)
					.filter((n) => {
						if (this.filter === 0) return !n.nomina_id;
						if (this.filter === 1) return n.type === "soporte";
						if (this.filter === 2) return n.type === "reemplazo";
						if (this.filter === 3) return n.type === "eliminacion";
					})
					.sort((a, b) => parseInt(a.number.replace(/\D/g, "")) - parseInt(b.number.replace(/\D/g, "")))
			);
		},
		canSendSome() {
			return this.nomina && this.nomina.some((nom) => !nom.codigo_dian);
		},
		isAllSent() {
			return this.nomina && this.nomina.every((nom) => nom.codigo_dian);
		},
		canContabilizarSome() {
			return this.nomina && this.nomina.some((nom) => !nom.contabilidad);
		},
		canRegenerate() {
			if (this.nomina && this.nomina.length === 0) return true;
			return this.filter === 0 && (!this.nomina || !this.nomina.every((nom) => nom.codigo_dian));
		},
		availableDevengables() {
			return getDevengables(this.concepts, this.selected.worker, this.selected.devengables);
		},
		availableDeductibles() {
			return getDeducibles(this.concepts, this.selected.worker, this.selected.devengables);
		},
	},
};
</script>
<style>
.nomina-container {
	width: 100%;
}
.tables input[type="number"],
.tables .v-messages__message {
	text-align: center;
}
</style>
